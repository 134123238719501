import axios from 'axios';
import * as config from 'src/config';
import { FETCH_STATUS } from 'src/constants';
import {
  TManfiestBacklogs,
  TManifestBacklogsEvents,
  TManifestBacklogsFilters,
  TManifestFilters,
  TManifestNonSession,
  TManifestSession,
  TManifestSyncBacklogsEventsPayload,
} from 'src/reducers/manifest';

export const SET_MANIFEST_SESSION = 'manifest/SET_MANIFEST_SESSION';
export const SET_MANIFEST_NON_SESSION = 'manifest/SET_MANIFEST_NON_SESSION';
export const SET_MANIFEST_SESSION_OVERVIEW = 'manifest/SET_MANIFEST_SESSION_OVERVIEW';
export const SET_MANIFEST_SERVICE_APPLETS = 'manifest/SET_MANIFEST_SERVICE_APPLETS';
export const SET_MANIFEST_FILTERS = 'manifest/SET_MANIFEST_FILTERS';
export const SET_MANIFEST_BACKLOGS = 'manifest/SET_MANIFEST_BACKLOGS';
export const SET_MANIFEST_BACKLOGS_FILTERS = 'manifest/SET_MANIFEST_BACKLOGS_FILTERS';
export const SET_MANIFEST_BACKLOGS_EVENTS = 'manifest/SET_MANIFEST_BACKLOGS_EVENTS';
export const CLEAR_MANIFEST_SERVICE_APPLETS = 'manifest/CLEAR_MANIFEST_SERVICE_APPLETS';
export const CLEAR_MANIFEST = 'manifest/CLEAR_MANIFEST';

export const setManifestSession = (value: Partial<TManifestSession>) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_SESSION, data: value });
  };
};

export const setManifestNonSession = (value: Partial<TManifestNonSession>) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_NON_SESSION, data: value });
  };
};

export const setManifestSessionOverview = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_SESSION_OVERVIEW, data: value });
  };
};

export const setManifestServiceApplets = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_SERVICE_APPLETS, data: value });
  };
};

export const setManifestFilters = (value: Partial<TManifestFilters>) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_FILTERS, data: value });
  };
};

export const setManifestBacklogs = (value: Partial<TManfiestBacklogs>) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_BACKLOGS, data: value });
  };
};

export const setManifestBacklogsFilters = (value: Partial<TManifestBacklogsFilters>) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_BACKLOGS_FILTERS, data: value });
  };
};

export const setManifestBacklogsEvents = (value: Partial<TManifestBacklogsEvents>) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_BACKLOGS_EVENTS, data: value });
  };
};

export const syncManifestBacklogsEvents = (payload: TManifestSyncBacklogsEventsPayload) => {
  return async (dispatch) => {
    dispatch({ type: SET_MANIFEST_BACKLOGS_EVENTS, fetchStatus: FETCH_STATUS.PENDING });

    return axios
      .post(`${config.endpoints.base_url}v1/events/batch`, payload)
      .then((res) => {
        dispatch({ type: SET_MANIFEST_BACKLOGS_EVENTS, fetchStatus: FETCH_STATUS.RESOLVED });
        return res;
      })
      .catch((err) => {
        dispatch({ type: SET_MANIFEST_BACKLOGS_EVENTS, fetchStatus: FETCH_STATUS.ERROR });
        throw new Error(err);
      });
  };
};

export const clearManifestServiceApplets = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_MANIFEST_SERVICE_APPLETS });
  };
};

export const clearManifest = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_MANIFEST });
  };
};
